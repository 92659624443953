import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  PageTitleWrapperStyles,
  SectionBody,
  FullPageStyles,
  StyledSection,
  InlineFlex,
} from '../components/pageStyles'

const Item = styled.div`
  flex: 1 1 100%;
  padding: 20px 20px 20px 20px;
  font-size: 2rem;

  i {
    font-size: 2rem;
    color: rgb(242, 176, 200);
  }

  span {
    font-size: 2rem;
    color: rgb(242, 176, 200);
    font-family: Gloria Hallelujah, cursive;
  }
`

const Contact = () => {
  const { contactJson } = useStaticQuery(graphql`
    query contactQuery {
      contactJson {
        title
      }
    }
  `)

  return (
    <Layout>
      <SEO pageTitle={contactJson.title} />
      <PageTitleWrapperStyles>
        <SectionBody>
          <Item>
            <h3>
              *Currently accepting only small to medium sized dogs. Please call
              to inquire.
            </h3>
          </Item>
        </SectionBody>
      </PageTitleWrapperStyles>
      <FullPageStyles>
        <StyledSection>
          <Item>
            <PageTitleWrapperStyles>
              <h5>Have Questions? Feel Free to Call or Text Me! </h5>
              <div style={{ textAlign: 'center' }}>
                <InlineFlex>
                  <a href="tel:+17325133607">732-513-3607</a>
                </InlineFlex>
                <InlineFlex>
                  <a
                    href="https://www.instagram.com/doggiedetailernj"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </InlineFlex>
                <br />
                <br />
                Have cute pictures of your pet after their groom? Post it on
                social media and use the hashtag &nbsp;
                <span>#DoggieDetailerNJ</span>
              </div>
              <span />
            </PageTitleWrapperStyles>
          </Item>
        </StyledSection>
      </FullPageStyles>
    </Layout>
  )
}

export default Contact
